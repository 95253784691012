import {form} from './components/form.js';

{
	var width 	= $(window).innerWidth();
	var height 	= $(window).innerHeight();

	// init functions
	form();

	// gmap
	// var latlng = new google.maps.LatLng(55.192550, 61.370855);
	// var myOptions = {
	// 	zoom: 17,
	// 	center: latlng,
	// 	mapTypeId: google.maps.MapTypeId.ROADMAP
	// };
	// var map = new google.maps.Map(document.getElementById('gmap'), myOptions);
	// var marker = new google.maps.Marker({
	// 	position: latlng,
	// 	map: map,
	// 	title: '"Дружный"'
	// });
	// end gmap

	// lightgallery
	$('.lightgallery').lightGallery({
		thumbnail: true,
		selector: '.lightgallery-list-item',
		download: false
	});
	$('.lightgallery-item').lightGallery({
		thumbnail: true,
		selector: 'this',
		download: false
	});
	// end lightgallery

	// masonry gallery
	var $portfolio = $('.portfolio-list').isotope({
		itemSelector: '.portfolio-item'
	});

	$('.portfolio-menu').find('a').on('click', function(e) {
		e.preventDefault();
		var elem 	= $(this);
		var wrap 	= elem.parent();
		var filter	= elem.data('filter');

		$('.portfolio-menu').find('li').removeClass('active');
		wrap.addClass('active');
		$portfolio.isotope({ filter: filter });
	});
	// end masonry gallery

	// carousel
	var $previewCarousel = $('.preview-list').owlCarousel({
		items: 1,
		loop: false,
		dots: true,
		nav: false,
		mouseDrag: false,
		touchDrag: false
	});
	var $reviewsCarousel = $('.reviews-list').owlCarousel({
		items: 3,
		margin: 30,
		dots: false,
		nav: true,
		navText: ['<i class="icon icon-arrow-left"></i>', '<i class="icon icon-arrow-right"></i>'],
		responsive: {
			0: 		{ items: 1 },
			768: 	{ items: 2 },
			1440: 	{ items: 3 }
		}
	});
	var $teamCarousel = $('.team-list').owlCarousel({
		items: 3,
		margin: 30,
		loop: false,
		dots: false,
		nav: true,
		navText: ["<span><i class='icon icon-arrow-left'></i></span>", "<span><i class='icon icon-arrow-right'></i></span>"],
		mouseDrag: true,
		touchDrag: true,
		responsiveClass: true,
		responsive: {
			0: 		{ items: 1 },
			600: 	{ items: 2 },
			1000: 	{ items: 3 }
		}
	});
	var $productsCarousel = $('.products-list').owlCarousel({
		items: 3,
		margin: 30,
		loop: false,
		dots: false,
		nav: true,
		navText: ["<span><i class='icon icon-arrow-left'></i></span>", "<span><i class='icon icon-arrow-right'></i></span>"],
		mouseDrag: true,
		touchDrag: true,
		responsiveClass: true,
		responsive: {
			0: 		{ items: 1 },
			600: 	{ items: 2 },
			1000: 	{ items: 3 }
		}
	});
	// end carousel

	// header affix links mobile
	if ( width < 768 ) {
		$('#navbar').find('a').on('click', function(e) {
			$('#navbar-toggle').trigger('click');
		});
	}

	// input mask
	$('.inputmask-phone').inputmask("mask", {
		"mask": "+7 (999) 999-99-99",
		showMaskOnHover: false
	});

	// select2
	$('select').select2({ minimumResultsForSearch: Infinity });

	// scroll sticky
	if ( $(window).scrollTop() > $('.main-header').height() ) {
		$('.main-header').addClass('sticky');
	} else {
		$('.main-header').removeClass('sticky');
	}
	
	$(window).on('scroll', function() {
		var elem = $(this);
		if ( elem.scrollTop() > 0 ) {
			$('.main-header').addClass('sticky');
		} else {
			$('.main-header').removeClass('sticky');
		}
	})

	var scrollOffset = 70;
	if ( width >= 768 && width <= 992 ) {
		scrollOffset = 70;
	}

	$('.scroll').bind('click', function(event) {
		var elem 	= $(this);
		var anchor 	=  $( elem.attr('href') );

		$('html, body').stop()
		.animate({
			scrollTop: anchor
			.offset()
			.top - scrollOffset }, 1200);
		event.preventDefault();
	});
}