export function form() {
	$('form').on('submit', function(e) {
		e.preventDefault();

		var $form = $(this),
		$btn = $form.find('button');

		if ( $form.find("[name=phone]").length > 0 ) {
			if ( $form.find("[name=phone]").val().indexOf("_") > 0 ) {
				$form.find("[name=phone]").css("border", "1px solid #A00000");
				$form.find('[name=phone]').siblings('span').addClass('disable');
				$form.find("p.phone-error").text("Телефон введен не полностью").fadeIn(0);
				e.stopPropagation();
				return false;
			} else {
				$form.find("[name=phone]").css("border", "1px solid #fff");
				$form.find('[name=phone]').siblings('span').removeClass('disable');
				$form.find("p.phone-error").text("").fadeOut(0);
			}
		}
		

		$btn.data( 'text', $btn.html() );
		$.ajax({
			type: 'post',
			dataType: 'json',
			url: 'php/contact.php',
			data: $form.serialize(),
			success: function (data) {
				if ( !data.success ) {
					if ( data.errors.name ) {
						$form.find('[name="name"]').css('border', '1px solid #A00000');
					} else {
						$form.find('[name="name"]').css('border', '1px solid #fff');
					}
					if ( data.errors.phone ) {
						$form.find('[name="phone"]').css('border', '1px solid #A00000');
					} else {
						$form.find('[name="phone"]').css('border', '1px solid #fff');
					}
				} else {
					$form.find('[name="name"]').css('border', '1px solid #fff');
					$form.find('[name="phone"]').css('border', '1px solid #fff');
					$form.find('span').removeClass('disable');
					$('.modal').modal('hide');
					$('#modal-success').modal('show');
				}
			},
			complete: function (data) {
				if ( JSON.parse(data.responseText).success ) {
					$form.find('input').val('');
				}
				$btn.text('');
				$btn.prepend( $btn.data('text') );
				setTimeout(function() {
					$('.modal').modal('hide');
				}, 10000);
			},
			error: function (a, b, c) {}
		});
	})
}